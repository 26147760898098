import Image from 'next/image'
import { Inter } from 'next/font/google'
import { useTranslations } from 'next-intl'
import dynamic from 'next/dynamic';
import Products from '@/components/home/Products';

const Blogs = dynamic(() => import('@/components/home/Blogs'));
const Features = dynamic(() => import('@/components/home/Features'));
const HomeSeo = dynamic(() => import('@/seo/HomeSeo'));
const Testimonials = dynamic(() => import('@/components/home/Testimonials'));
const ItWorks = dynamic(() => import('@/components/home/ItWorks'));
const Facts = dynamic(() => import('@/components/home/Facts'));
const HeroSetion = dynamic(() => import('@/components/home/HeroSection'));




const inter = Inter({ subsets: ['latin'] });

export default function Home() {
  const t = useTranslations('Index');

  
  return (
    <>
      <HomeSeo />
      <HeroSetion />
      <Facts />
      <ItWorks />
      <Products />
      <Blogs />
      <Features />
      <Testimonials />
    </>
  );
}


export async function getStaticProps({ locale }) {
  return {
    props: {
      messages: (await import(`../../lang/${locale}.json`)).default,
    },
  };
}