import React from 'react'
import ProductCard from '../product/ProductCard'
import productsData from '@/data/product'


function Products() {
  return (
    <div className=" flex flex-col gap-3 items-center  mx-auto px-5">
        <div className="text-center mb-8 mt-10">
          <p className="uppercase text-[#000000]">innovative design tools</p>
          <h2 className="text-3xl font-bold md:text-5xl">AutoCAD Products</h2>
          <p className="mx-auto mt-4 max-w-lg text-[#647084]">
            Explore our range of cutting-edge AutoCAD solutions designed to
            enhance your workflow and help you achieve precision in every
            project.
          </p>
        </div>
        <div className="grid gap-5 grid-cols-1 sm:grid-cols-1 pt-10 mt-10 border-t-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ">
          {productsData.map((e, i) => (
            <ProductCard key={i} {...e} />
          ))}
        </div>
      </div>
  )
}

export default Products