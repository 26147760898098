'use client'


import Image from "next/image"
import Link from "next/link"
import Btn from "../uikit/Btn"






export default function ProductCard({title,img,slug,shortDescription,price,comparedPrice}) {
    return (
        <Link href={"/product/" + slug} className=" h-fit mx-auto  rounded-lg relative flex flex-col gap-2 border-2 border-gray-300 bg-gray-100 overflow-hidden  w-72">
            <div className=" h-fit border-black borde flex px-5 pt-4  font-koulen flex-col  ">
                <span className="text-[8px] font-medium bg-[#FF0000] w-fit rounded-full  px-2 mb-2 py-0.5 text-white ">50% Off</span>
                <h2 aria-label={title} className="capitalize font-bold text-md text-balance">{title}</h2>
                {/* <p className="text-xs text-gray-700">{product.description}</p> */}
                <div className="w-full border my-2"></div>
                <h3 className="capitalize tracking-wide text-xl font-bold">{price}<span className="capitalize line-through ml-2 text-xs text-gray-600 font-bold">{comparedPrice}$</span></h3>
            </div>
            <Image draggable="false" onLoadingComplete={(img) => img.classList.remove("opacity-0")} src={img} width={300} height={300} alt={title} className="h-1/2 w-full  transition-opacity opacity-0 duration-[2s] object-cover" />
            {/* <button  className = " bottom-5  absolute z-[10] w-[85%] left-[50%] translate-x-[-50%] mx-auto px-8 py-2 [box-shadow:#EC1C1C_6px_6px] hover:shadow-none  border-[#EC1C1C] bg-black text-[#fff]   inline-block rounded-xl border   text-center font-semibold   transition-all ease-in-out duration-300 hover:border-black  ">
          Buy Now
        </button> */}
        </Link>
    )
}

